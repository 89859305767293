.template-container {
  width: 300px;
}
.square {
  width: 100%;
  height: 80px;
  background: #25aae1;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;

  border-top: 120px solid #25aae1;
}

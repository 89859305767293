a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* corrections made to ant design */
.ant-layout {
  background: none;
}
.ant-layout-header {
  height: auto;
  background: none;
  padding: 0;
}
.ant-layout-content {
  margin-top: 40px;
}
.ant-menu {
  background: none;
}

.ant-btn a:hover {
  color: #fbfdff;
}
.ant-btn a {
  margin-left: 3px;
}

.center {
  display: flex;
  justify-content: center;
}

.col {
  flex-direction: column;
  align-items: center;
}
.bg-none {
  background: none;
}
.bg-blue {
  background: #86bed6f3;
}

.light {
  color: #fff !important;
}

/* login page */
.mt {
  margin-top: 80px;
}
.mt-half {
  margin-top: 40px;
}
.mt-1 {
  margin-top: 120px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px !important;
}
/* dashboard */
.d-block {
  display: block;
}
.d-none {
  display: none;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mr-5 {
  margin-right: 5px;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.w-100 {
  width: 100%;
}

.curve-sm {
  border-radius: 8px;
}
.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.round-icon.dark-svg svg {
  /* fill: #232230; */
  color: #232230;
  /* stroke: #232230; */
}

.bg-grey {
  background: #f5f5f5 !important;
}

.bg-dark-grey {
  background: #232230 !important;
}
.bg-dark {
  background: rgb(12, 11, 11) !important;
}

.round {
  border-radius: 50%;
}
.p-0 {
  padding: 0;
}

.p-5 {
  padding: 15px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}
.py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.py-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.chat-box {
  padding: 10px;
  background: #fff;
  height: 35px;
  border-radius: 7px;
}

.red {
  color: red;
}

.layout {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.pr-abs {
  position: absolute;
  width: 100%;
}

.blue {
  color: #00a8ed !important;
}
.ash {
  color: #232230 !important;
}

.sp-txt {
  font-size: 1rem;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.v-nav-item-center {
  display: flex;
  align-items: center;
  padding-left: 15px !important;
  margin-bottom: 0 !important;
  margin: 0 !important;
  height: 60px !important;
  transition: all ease-out 0.1 0.2s;
}
/* logout */
.v-nav-item-center:last-child {
  margin-top: 40px !important;
}
/* create an exception for logo */
.v-nav-item-center:first-child,
.v-nav-item-center:first-child:hover,
.v-nav-item-center:first-child:active {
  background: none !important;
  border-right: none !important;
  margin-bottom: 30px !important;
  height: 40px !important;
}

/* create an exception for logo */

.v-nav-item-center:hover,
.v-nav-item-center:active,
.v-nav-item-center.active {
  background: #00aaed1f !important;
  border-right: 8px solid #0491c9 !important;
}
.v-nav-item-center::after {
  content: "" !important;
  display: none !important;
  /* transform: scaleY(1);
    
    
    width: 20px;
    height: 50px !important;
    flex: 1;
    align-self: stretch; */

  background: #012b3b;
}
.txt-center {
  text-align: center;
}
.txt-red {
  color: #ed2400;
}
.txt-green {
  color: #86ed00;
}

.txt-light {
  color: #fff !important;
}

.txt-light {
  color: #fff !important;
}

.nav {
  background: #f5f5f5;
  padding-top: 100px;
  height: 70vh;
  justify-content: flex-start;
  width: 90%;
}

/* dashboard cards */
.center-them {
  align-items: center;
}
.twin-card {
  background: #ffffff33;
  padding: 8px;
  border-radius: 8px;
  color: #ffffff;
}
.main-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.round-corners {
  border-radius: 45px;
}
.p-4 {
  padding: 12px;
}
.h-60 {
  height: 50px;
}

.round-corners-sm {
  border-radius: 5px;
}
.d-flex {
  display: flex;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-stretch {
  justify-content: stretch;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.round-icon {
  background: #ffffff33;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.round-icon.small-space {
  margin-right: 5px !important;
}

.scanpass-card {
  background: #25aae1;
  border-radius: 8px;
  height: 100%;

  display: flex;

  align-items: center;
  justify-content: center;
}
.mt-min {
  margin-top: 10px;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-side-logo {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px;
}
.txt-blue {
  color: #25aae1 !important;
}
.w-full {
  width: 100%;
}

.w-200 {
  width: 200px;
}
.w-50 {
  width: 50%;
}
.w-45 {
  width: 45%;
}

.custom-sidebar {
  /* width: 265px !important;
    flex: 0 0 265px !important;
    max-width: 265px !important;
    min-width: 265px !important; */
  max-height: 100vh;
  overflow-y: auto;

  position: sticky;
  top: 0;
}
.custom-sidebar::-webkit-scrollbar {
  width: 7px;
}

.custom-sidebar::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.custom-sidebar::-webkit-scrollbar-thumb {
  background-color: #376888;
  border-radius: 100px;
}

.sp-card .ant-card-body {
  padding: 0;
}
.ml-10 {
  margin-left: 10px;
}

.bg-light-grey {
  background: #adabab !important;
}
.bg-lighter-grey {
  background: #e4e4e4 !important;
}
.bg-light {
  background: #fff;
}
section.remove-section-bg {
  background: none !important;
}
.scanpass-card div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.txt-danger {
  color: rgb(255, 23, 23) !important;
}
.txt-light {
  color: #fff;
}

.site-dropdown-context-menu {
  color: #777;
  background: #f7f7f7;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
}
.custom-context-menu {
  padding: 15px 15px 10px 15px;
}
.custom-context-menu li {
  margin-bottom: 15px;
}
.drop-shadow-sm {
  filter: drop-shadow(2px 10px 9px rgba(0, 0, 0, 0.048));
}
.w-320 {
  width: 320px;
}
.loading-container {
  height: 45vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-sp-hover:hover .ant-menu-title-content {
  color: rgb(36, 35, 35) !important;
}

.mini-header-bg {
  background: #dff6ff;
  overflow-y: auto;
}

.bold {
  font-weight: bold;
}

.round-corners {
  border-radius: 45px;
}
.cursor-pointer {
  cursor: pointer;
}

.mini-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.mini-content-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
}

.mobile-shadow-header {
  filter: drop-shadow(2px 10px 9px rgba(0, 0, 0, 0.048));
}

.align-center {
  align-items: center;
}
.txt-green {
  color: #018538;
}
.txt-red {
  color: #850501;
}
.detail-content {
  font-weight: bold !important;
  text-transform: capitalize;
}

/* table stuff */

.ant-table-thead > tr > th {
  background: #f09f9f;
}

/* gate pass */
.detail-content {
  display: block;
  margin-top: 7px;
  font-size: 1.005rem;
  font-weight: 400;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.header-content-container {
  padding-left: 30px;
  padding-right: 30px;
  background: #fff !important;
}

/* dashboard layout */
.dashboard-content-container {
  overflow-y: scroll;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 1200px; */
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 100px;
}
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}

.isMobile {
  display: none;
}
.isDesktop {
  display: block;
}

/* @media screen and (max-width: 700px) {
    .isMobile{
      display: block;
    }
    .isDesktop{
      display: none;
    }
   } */

div.ql-editor {
  height: 200px;
}

.custom-modal .ant-modal-content {
  background: none;
  box-shadow: none;
  display: flex;
  align-items: center;
}

/* drawer responsiveness */

@media screen and (min-width: 700px) {
  .ant-drawer-content-wrapper {
    width: 600px !important;
  }

  .mobile-navigation {
    display: none;
  }
  .only-mobile {
    display: none;
  }
  .only-desktop .form-container {
    margin-top: 60px;
    margin-left: 100px;
    max-width: 500px;
  }
  .profile-nav {
    display: flex;
    flex-direction: column;
    background: #faf2f3 !important;
    padding: 100px 1.5rem;
    height: 70vh;
    justify-content: flex-start;
    width: 90%;
  }
  .profile-nav .profile-nav-container span {
    display: block;
  }
}
@media screen and (max-width: 700px) {
  .profile-layout .mt {
    margin-top: 30px;
    margin-bottom: 80px;
  }
  .avatar-profile {
    margin-top: 20px;
  }
  .avatar-profile .profile-logo-container {
    margin-top: 20px;
  }
  .avatar-profile span,
  .avatar-profile h5 {
    color: #001529 !important;
  }
  .profile-nav {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    /* height: 70vh; */
    justify-content: stretch;
    width: 100%;
  }
  .profile-nav .profile-nav-container {
    display: flex;

    justify-content: space-between;
    width: 100%;
  }
  .profile-nav .profile-nav-container span {
    display: block;
    padding: 0 20px;
    font-size: 0.82rem;
  }

  .custom-sidebar {
    display: none;
  }
  .only-desktop {
    display: none;
  }
  .only-mobile {
    display: block;
  }
  .only-mobile h3 {
    font-size: 1.3rem !important;
    text-align: left;
  }
  .only-mobile .form-container {
    /* background: red; */
    min-width: 320px !important;
    width: 100% !important;
    padding: 10px 10px;
    margin-bottom: 50px;
  }

  .dashboard-content-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ant-layout-header {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .top-header .ant-space {
    gap: 20px !important;
  }
  .db-create-btn {
    display: none;
  }
  .scanpass-card {
    display: none;
  }
  .mobile-navigation {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 20px;
    z-index: 100;
    left: 0;
    right: 0;
    padding: 0 22px;
  }
  .mobile-navigation .mobile-modal-card {
    box-shadow: 13px 13px 20px -11px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 13px 13px 20px -11px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 13px 13px 20px -11px rgba(0, 0, 0, 0.75);

    background: #001529;
    border-color: #001529;
  }
  .mobile-navigation .mobile-modal-card a:hover svg path,
  .mobile-navigation .mobile-modal-card a.active svg path {
    stroke: rgb(64, 232, 238);
  }
  .mobile-navigation .mobile-modal-card button:hover svg .the-round-one {
    fill: rgb(64, 232, 238);
  }
  .mobile-navigation .mobile-modal-card button:hover svg {
    fill: rgb(64, 232, 238);
  }
  .scan-pass-no-end {
    justify-content: stretch !important;
  }
  .mobile-space {
    flex-direction: column;
    align-items: stretch;
  }
  .entity-table-header {
    flex-direction: column;
    gap: 15px;
  }
}

/* legibility */

.ant-input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(18, 18, 18, 0.838);
}

.low-opacity:hover,
.low-opacity:focus {
  opacity: 0.7;
}
